<template>
  <div>
    <iframe width="100%" height="1000px"
      src="https://lookerstudio.google.com/embed/u/0/reporting/e803babc-2ad1-4a40-8d78-dfcd8f5c58c9/page/cgl9C"
      frameborder="0" style="border:0" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
}
</script>
